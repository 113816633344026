import { gql } from 'graphql-tag'
import queryPageFields from '../../../core/data-layer/pages/page/query-page-fields'
import { fragmentGlobalDataCity} from '../../../core/data-layer/global-data/fragments'
import { fragmentModuleVideo, fragmentModuleMedia, fragmentModuleInternalVideo, fragmentModuleNavigationLink } from '../../../core/data-layer/modules/fragments'
import { fragmentBlockEventForm, fragmentBlockLiveStreamVideo } from '../../../core/data-layer/blocks/fragments'

export default gql`
    ${fragmentModuleMedia}
    ${fragmentModuleNavigationLink}
    ${fragmentGlobalDataCity}
    ${fragmentBlockEventForm}
    ${fragmentBlockLiveStreamVideo}
    ${fragmentModuleVideo}
    ${fragmentModuleInternalVideo}
    query($slug: String!, $locale: String!, $preview: Boolean!) {
        page: pageInvestorRelationsEventsDetailCollection(limit: 1, where: { slug: $slug }, locale: $locale, preview: $preview) {
            items {
                ${queryPageFields}
                city {
                    ... on GlobalDataCity {
                        ...GlobalDataCityFragment
                    }
                }
                description {
                    json
                }
                startDate
                endDate
                form {
                    ...BlockEventFormFragment
                }
                liveStreamVideo {
                    ...BlockLiveStreamVideoFragment
                }
                relatedDocumentsCollection(limit: 10) {
                    items {
                        ... on ModuleMedia {
                            ...ModuleMediaFragment
                        }
                        ... on ModuleNavigationLink {
                            ...ModuleNavigationLinkFragment
                        }
                        contentType: __typename
                    }
                }
                bannerImage {
                    ...ModuleMediaFragment
                }
                video {
                    ...on ModuleVideo {
                        ...ModuleVideoFragment
                    }
                    ... on ModuleInternalVideo {
                        ...ModuleInternalVideoFragment
                    }
                }
            }
        }
        news: pagePressAndMediaDetailCollection(where: { slug_exists: true, pressCategory: "investor_relations", hiddenInHomePage_not: true }, order: date_DESC, limit: 3, locale: $locale, preview: $preview) {
            items {
                sys {
                    id
                }
                title
                date
                pressCategory
                city {
                    sys {
                        id
                    }
                    name
                }
                image {
                    ...ModuleMediaFragment
                }
            }
        }
    }
`
