import { gql } from 'graphql-tag'
import queryPageFields from '../../../core/data-layer/pages/page/query-page-fields'
import { fragmentModuleVideo, fragmentModuleMedia, fragmentModuleInternalVideo, fragmentModuleNavigationLink } from '../../../core/data-layer/modules/fragments'
import { fragmentBlockFaqGroup } from '../../../core/data-layer/blocks/fragments'
import { fragmentGlobalDataCity } from '../../../core/data-layer/global-data/fragments'

export default gql`
  ${fragmentBlockFaqGroup}
  ${fragmentModuleMedia}
  ${fragmentModuleNavigationLink}
  ${fragmentModuleVideo}
  ${fragmentModuleInternalVideo}
  ${fragmentGlobalDataCity}
  query GetPageInvestorRelations($slug: String!, $locale: String!, $startDate: DateTime!, $preview: Boolean!) {
      page: pageInvestorRelationsCollection(limit: 1, where: { slug: $slug }, locale: $locale, preview: $preview) {
          items {
              ${queryPageFields}
              headerTitle
              headerEventLink {
                  ...ModuleNavigationLinkFragment
              }
              headerResultsLink {
                  ...ModuleNavigationLinkFragment
              }
              financialsTitle
              seeAllEventsLink {
                  ...ModuleNavigationLinkFragment
              }
              mediaAsset {
                  ...on ModuleMedia {
                      ...ModuleMediaFragment
                  }
                  ...on ModuleVideo {
                     ...ModuleVideoFragment
                  }
                  ... on ModuleInternalVideo {
                      ...ModuleInternalVideoFragment
                  }
              }
              faq {
                  ...BlockFaqGroupFragment
              }
          }
      }
      events: pageInvestorRelationsEventsDetailCollection(limit: 30, locale: $locale, where: { startDate_gt: $startDate }, order: startDate_DESC, preview: $preview) {
          items {
              sys {
                  id
              }
              title
              city {
                  ... on GlobalDataCity {
                      ...GlobalDataCityFragment
                  }
              }
              startDate
              endDate
          }
      }
      news: pagePressAndMediaDetailCollection(where: { slug_exists: true, pressCategory: "investor_relations", hiddenInHomePage_not: true }, order: date_DESC, limit: 3, locale: $locale, preview: $preview) {
          items {
              sys {
                  id
              }
              title
              date
              pressCategory
              city {
                  sys {
                      id
                  }
                  name
              }
              image {
                  ...ModuleMediaFragment
              }
          }
      }
  }
`
