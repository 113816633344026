import { gql } from 'graphql-tag'
import queryPageFields from '../../../core/data-layer/pages/page/query-page-fields'
import { fragmentModuleUsp, fragmentModuleMedia } from '../../../core/data-layer/modules/fragments'

export default gql`
    ${fragmentModuleMedia}
    ${fragmentModuleUsp}
    query($slug: String!, $locale: String!, $preview: Boolean!) {
        page: pageInvestorRelationsFinancialsAnnualReportCollection(limit: 1, where: { slug: $slug }, locale: $locale, preview: $preview) {
            items {
                ${queryPageFields}
                year
                quoteImage {
                    ...ModuleMediaFragment
                }
                quoteText
                quoteAuthorName
                quoteAuthorRole
                reportTitle
                reportText {
                    json
                }
                reportSignature
                image {
                    ...ModuleMediaFragment
                }
                summaryTitle
                summaryFootnote
                summaryUspsCollection (limit: 6) {
                    items {
                        ...ModuleUspFragment
                    }
                }
                downloadPdf {
                    ...ModuleMediaFragment
                }
            }
        }
    }
`
