import { gql } from 'graphql-tag'
import queryPageFields from '../../../core/data-layer/pages/page/query-page-fields'
import { fragmentGlobalDataCity } from '../../../core/data-layer/global-data/fragments'

export default gql`
    ${fragmentGlobalDataCity}
    query($slug: String!, $locale: String!, $preview: Boolean!) {
        page: pageInvestorRelationsEventsCollection(limit: 1, where: { slug: $slug }, locale: $locale, preview: $preview) {
            items {
                ${queryPageFields}
            }
        }
        events: pageInvestorRelationsEventsDetailCollection(limit: 30, locale: $locale, order: startDate_DESC, preview: $preview) {
            items {
                sys {
                    id
                }
                title
                city {
                    ... on GlobalDataCity {
                        ...GlobalDataCityFragment
                    }
                }
                description {
                    json
                }
                startDate
                endDate
            }
        }
    }
`
