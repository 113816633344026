import { gql } from 'graphql-tag'
import fragmentFinancialsFinancialRecap from './fragment-financials-financial-recap'

export default gql`
  ${fragmentFinancialsFinancialRecap}
  query($year: Int!, $preview: Boolean) {
      financialsHalfYear: financialsFinancialRecapCollection(where: { year_lte: $year, period_not_in: ["FY"] }, order: [year_DESC, period_DESC], limit: 8, preview: $preview) {
          items {
              ...on FinancialsFinancialRecap {
                  ...FinancialsFinancialRecapFragment
              }
          }
      }
      financialsFullYear: financialsFinancialRecapCollection(where: { year_lte: $year, period_in: ["FY"] }, order: [year_DESC], limit: 4,preview: $preview) {
          items {
              ...on FinancialsFinancialRecap {
                  ...FinancialsFinancialRecapFragment
              }
          }
      }
  }
  
`
