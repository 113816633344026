import { gql } from 'graphql-tag'
import queryPageFields from '../../../core/data-layer/pages/page/query-page-fields'
import { fragmentModuleUsp, fragmentModuleMedia, fragmentModuleMediaCard } from '../../../core/data-layer/modules/fragments'
import { fragmentReportsContentBlock, fragmentReportsGrowthPillarBlock } from '../reports/fragments'
import { fragmentBlockLogoBar } from '../../../core/data-layer/blocks/fragments'

export default gql`
    ${fragmentReportsContentBlock}
    ${fragmentReportsGrowthPillarBlock}
    ${fragmentModuleUsp}
    ${fragmentModuleMedia}
    ${fragmentBlockLogoBar}
    ${fragmentModuleMediaCard}
    query GetPageInvestorRelationsFinancialsShareholderLetter($slug: String!, $locale: String!, $preview: Boolean!) {
        page: pageInvestorRelationsFinancialsShareholderLetterCollection(limit: 1, where: { slug: $slug }, locale: $locale, preview: $preview) {
            items {
                ${queryPageFields}
                image {
                    ...ModuleMediaFragment
                }
                highlightsYear
                highlightsPeriod
                highlightsFootnote
                highlightsUspsCollection (limit: 4) {
                    items {
                        ...ModuleUspFragment
                    }
                }
                letterItemsCollection (limit: 10) {
                    items {
                        ... on ReportsFteGrowthBlock {
                            headerImageGraph {
                                ...ModuleMediaCardFragment
                            }
                            imageGraphsCollection (limit: 7) {
                                items {
                                    ...ModuleMediaFragment
                                }
                            }
                        }
                        ... on ReportsContentBlock {
                            ...ReportsContentBlockFragment
                        }
                        ... on ReportsGrowthPillarBlock {
                            ...ReportsGrowthPillarBlockFragment
                        }
                        ... on ReportsHighlightsBlock {
                            itemsCollection  {
                                items {
                                    ...ModuleUspFragment
                                }
                            }
                        }
                        contentType: __typename
                    }
                }
                letterSignature1Name
                letterSignature1Role
                letterSignature2Name
                letterSignature2Role
                letterSignature3Name
                letterSignature3Role
                discussionOfFinancialResultsItemsCollection (limit: 3) {
                    items {
                        ... on ReportsContentBlock {
                            ...ReportsContentBlockFragment
                        }
                        contentType: __typename
                    }
                }
                growthPillarsItemsCollection (limit: 5)  {
                    items {
                        ...ReportsGrowthPillarBlockFragment
                    }
                }
                financialStatementsTitle
                financialStatementsPdf {
                    ...ModuleMediaFragment
                }
                notice {
                    json
                }
                revenueFlowData
            }
        }
    }
`
