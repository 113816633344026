import { gql } from 'graphql-tag'
import queryPageFields from '../../../core/data-layer/pages/page/query-page-fields'
import { fragmentBlockTabContent } from '../../../core/data-layer/blocks/fragments'

export default gql`
    ${fragmentBlockTabContent}
    query($slug: String!, $locale: String!, $preview: Boolean!) {
        page: pageInvestorRelationsGovernanceCollection(limit: 1, where: { slug: $slug }, locale: $locale, preview: $preview) {
            items {
                ${queryPageFields}
                tabsCollection (limit: 10) {
                    items {
                        ...BlockTabContentFragment
                    }
                }
            }
        }
    }
`
